<template lang="pug">
    v-container( fluid )
        h3.mb-10.mt-5 Cliente
        v-row
            v-col(cols="12" md="4")
                v-text-field( outlined v-model="client.phone" :rules="rules.phone" label="Telefone" v-mask="['+## (##) ####-####', '+## (##) #####-####']" @blur="searchByPhone" required )

            v-col(cols="12" md="4")
                <v-text-field outlined v-model="client.name" :rules="rules.name" :counter="50" label="Nome" required></v-text-field>
            

            v-col(cols="12" md="4")
                <v-text-field outlined v-model="client.lastname" :rules="rules.lastname" :counter="50" label="Sobrenome" required></v-text-field>
        v-row
            v-col(cols="9" md="4" lg="5")
                v-combobox(
                    v-model="client.street.name"
                    :items="search.streets.result"
                    :loading="search.streets.loading"
                    :search-input.sync="search.streets.word"
                    hide-no-data
                    item-text="name"
                    item-value="name"
                    label="Endereço"
                    outlined
                    :counter="100"
                    return-object
                    clearable
                    @change="selectStreet"
                    auto-select-first
                    @blur="searchByAddress"
                )
                    
                    template(slot="item" slot-scope="{item, parent}")
                        v-list-item-content
                            v-list-item-title(v-html="item.name")
                            small(class="text--disabled") {{item.city.name}} - {{item.city.state.name}}
            

            v-col(cols="3" md="2" lg="1")
                <v-text-field outlined v-model="client.address_number" counter="10" label="Nro." @blur="searchByAddress" required></v-text-field>
            
            v-col(cols="12" md="3")
                <v-text-field outlined v-model="client.address_comp" counter="50" label="Complemento" required></v-text-field>
            
            v-col(cols="12" md="3")
                <v-text-field outlined v-model="client.address_ref" counter="50" label="Referência" required></v-text-field>
        
        v-row
            v-col(cols="12" md="6")
                v-combobox(
                    v-model="client.neighbourhood.label"
                    :items="search.neighbourhood.result"
                    :loading="search.neighbourhood.loading"
                    :search-input.sync="search.neighbourhood.word"
                    hide-no-data
                    item-text="label"
                    item-value="label"
                    label="Bairro"
                    outlined
                    :counter="50"
                    return-object
                    clearable
                    @change="selectNeighbourhood"
                    auto-select-first
                )

                
            
            v-col(cols="12" md="6")
                v-autocomplete(
                    outlined
                    v-model="client.street.city"
                    :items="search.cities.result"
                    :loading="search.cities.loading"
                    :search-input.sync="search.cities.word"
                    label="Cidade"
                    item-text="name"
                    item-value="name"
                    hide-no-data
                    return-object
                    clearable
                    auto-select-first
                    )
                    template(slot="item" slot-scope="{item, index, parent}")
                        v-list-item-content
                            v-list-item-title(v-html="item.name")
                            small(class="text--disabled") {{item.state.name}}

</template>


<script>
export default {
    name: "ClientForm",
    props: [ 'id' ],
    data: function(){
        return {
            client: {
                name: '',
                lastname: '',
                phone: '',
                street:{
                    id: null,
                    name: null,
                    city: {}
                },
                neighbourhood:{
                    id: null,
                    label: null
                },
                address_number: '',
                address_comp: '',
                address_ref: '',
            },

            rules: {
                phone: [
                    v => !!v || 'Telefone é obrigatório.',
                ],
                name: [
                    v => !!v || 'Nome é obrigatório',
                    v => v.length >= 2 || 'O Nome deve conter mais de 2 caracteres',
                ],
                lastname: [
                    v => !!v || 'Sobrenome é obrigatório',
                    v => v.length >= 2 || 'O Sobrenome deve conter mais de 2 caracteres',
                ],
            },// rules



            search: {
                cities: {
                    loading: false,
                    word: 'VISCONDE DO RIO BRANCO',
                    result: []
                },

                streets: {
                    loading: false,
                    word: null,
                    result: []
                },

                neighbourhood: {
                    loading: false,
                    word: null,
                    result: []
                },
            },
            loadByPhone: false,
            loadByAddress: false, 
        }
    },

    computed: {
        
    },// computed

    watch:{
        // 'value' :function (){
        //     this.client = this.value
        // },
        'client' : {
            handler: function(){
                this.$emit( 'input', this.client )
            },
            deep: true
        },
        
        'search.cities.word' : function( search ){

            let self = this
            
            if( search === null ) return

            search = search.toUpperCase()
            self.search.cities.word = search;

            if( self.search.cities.loading  ) return

            this.$nextTick(function(){
                if( search.length >= 3){
                    self.search.cities.loading = true
                    
                    this.api.post( 'utils/cities/search', { s: search } ).then(function( response ){
                        self.search.cities.result = response.data
                    }).finally(function(){
                        self.search.cities.loading = false;
                    })
                }    
            });
           
          
      },

      'search.streets.word' : function( search ){

            let self = this

            if( self.search.streets.loading ) return
            if( search === null ) return

            if( search.length >= 3){
                self.search.streets.loading = true
                
                this.api.post( 'utils/streets/search', { s: search } ).then(function( response ){
                    self.search.streets.result = response.data
                }).finally(function(){
                    self.search.streets.loading = false;
                })
            }
          
      },

      'search.neighbourhood.word' : function( search ){
            let self = this
            
            if( search === null ) return

            search = search.toUpperCase()
            self.search.neighbourhood.word = search;

            if( self.search.neighbourhood.loading  ) return

            this.$nextTick(function(){

                if( search.length >= 3){
                    self.search.neighbourhood.loading = true
                   
                    this.api.post( 'utils/neighbourhood/search', { s: search } ).then(function( response ){
                        self.search.neighbourhood.result = response.data
                    }).finally(function(){
                        self.search.neighbourhood.loading = false;
                    })
                }
            })
          
      },


      
        
    },// Watch

    created: function(){
        if( !this.id )
            this.client.phone = "5532 ";
            this.search.cities.result = [{ "id": 2415, "name": "VISCONDE DO RIO BRANCO", "state": { "id": 11, "name": "MINAS GERAIS", "uf": "MG" } }]
            this.client.street.city = { "id": 2415, "name": "VISCONDE DO RIO BRANCO", "state": { "id": 11, "name": "MINAS GERAIS", "uf": "MG" } };
            
    },


    methods:{
        searchByPhone: function(){

            let self = this;

            if( self.loadByPhone !== false || self.loadByAddress !== false ) return

            let number = self.client.phone.replace(/\D/g, "")
            
            if(number.length >= 12){
                
                self.api.post( 'clients/search/phone', { s: number } ).then(function( response ){
                     if( response.data ){
                         self.search.cities.result = [ response.data.street.city ]
                         self.client = response.data
                         self.loadByPhone = true;
                     }else{
                         self.loadByPhone = false;
                     }
                })
             }
        },

        getById: function( id ){
            let self = this ;
            self.api.get( `client/${id}` ).then(function( response ){
                    if( response.data ){
                        self.search.cities.result = [ response.data.street.city ]
                        self.client = response.data
                        self.loadByID = true;
                    }else{
                        self.loadByID = false;
                    }
            })
        },

        selectNeighbourhood: function( v ){
            if( v !== null && typeof(v) === 'object' ){
                this.client.neighbourhood = v
                this.search.neighbourhood.result = [ v ]
            }
            else{
                this.resetNeighbourhood()
                this.$nextTick(function(){
                    this.client.neighbourhood.label = v
                })
            }
                             
        },

        resetNeighbourhood: function(){
            this.client.neighbourhood.id = null
            this.client.neighbourhood.label = null
            this.search.neighbourhood.result = []
        },

        selectStreet: function( v ){
            if( v !== null && typeof(v) === 'object' ){
                this.client.street = v
                this.search.cities.result = [ v.city ]
            }
            else{
                this.resetStreet()
                this.$nextTick(function(){
                    this.client.street.name = v
                })
            }
                             
        },

        resetStreet: function(){
            delete this.client.street.combined
            this.client.street.id = null
            this.client.street.name = null
            this.search.streets.result = []
        },


        searchByAddress: function(){
            let self = this
            
            if( self.loadByPhone !== false || self.loadByAddress !== false ) return

            if( self.client.street.id !== null &&  self.client.address_number ){
                self.api.post( 'clients/search/address', {
                        street_id: self.client.street.id,
                        number: self.client.address_number,
                    } ).then(function( response ){
                    if( response.data ){
                        self.search.cities.result = [ response.data.street.city ]
                        self.client = response.data
                        self.loadByAddress = true;
                    }else{
                        self.loadByAddress = false;
                    }
                })
            }

        },

        
    },// Methods
}
</script>